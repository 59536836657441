<template>
  <div>

    <div class="inside-os">
      <div class="com-title">
      <span :class="{active:zl_zb==1}" @click="setzlzb(1)">会员资料</span>
      <span style="width:10px"></span>
       <span :class="{active:zl_zb==2}" @click="setzlzb(2)">账变明细</span>
    </div>
    <div class="report" v-if="zl_zb==2">
      <table class="com-table">
        <tr>
           
              <th  style="width:200px">时间</th>
             
              <th style="width:200px">
                <span v-if="filterQianbao.length==0">额度类型</span>
                <select style="width:100px" v-model="walletnameeng" v-if="filterQianbao.length>=1">
                  <option value="" >全部额度</option>
                  
                <option  :value="item.walletnameeng"
                 v-for="item in filterQianbao" :key="item.walletnameeng">{{item.walletnamecn}}</option>
                 
                </select>
              </th>
              <th>交易类型</th>
              <th style="width:150px">初始余额</th>

            
              <th style="width:150px">账变明细</th>
              <th style="width:150px">余额</th>

          
            </tr>
            <tr v-for="item in  dataSource" :key="item.ID">
            <td>{{item.Time|substrSome}}</td>
            <td>{{item.WalletName}}</td>
            <td>{{item.ExpInfo}}</td>
            <td>{{item.OldGold}}</td>
            <td>  <span class="bold" :class="item.OpGold>=0?'red':'green'">{{item.OpGold}}</span>  </td>
            <td>{{item.NewGold}}</td>
            </tr>
      </table>
      <div class="page">
      <el-pagination :page-sizes="[20,50,100,200]" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pages.pagenum" :page-size="pages.pagecount" layout="sizes,total, prev, pager, next, jumper" :total="totals">
      </el-pagination>
    </div>
    </div>
    <!-- 会员资料 -->
      <div class="water-os" v-if="zl_zb==1">
        <!-- <el-form :model="form" :rules="rules"> -->
        <table class="com-table">
          <tr>
            <th colspan="2">会员资料</th>
          </tr>
          <tr>
            <td>会员账号</td>
            <td>{{userInfo.isshiwan==1?'试玩':userInfo.account}}</td>
          </tr>
          <tr>
            <td>账户状态</td>
            <td>正常</td>
          </tr>
          <tr>
            <td style="display: table-cell;
         vertical-align:middle;">

              额度 <br>
              <span v-if="userInfo.wallettype=='credit'">[可用/信用]</span>

            </td>
            <td>
              <template v-if="accInfo&&accInfo.walletinfos.length>0">

                <div v-for="item in filterQianbao" :key="item.walletname">
                  <span style="display:inline-block;width:55px">{{item.walletnamecn}}</span> [<span class="red" style="">{{item.amount}}</span>
                  <span v-if="userInfo.wallettype=='credit'">/<span class="red">{{item.amountlimit}}</span></span>]

                </div>
              </template>
            </td>
          </tr>
        </table>
        <table class="com-table w-t2">
          <tr>
            <th class="kkc">游戏</th>
            <td>
              <div class="tab">
                <span v-for="gm in gamelist" :key="gm.Cato" @click="changeGame(gm)" :class="roomeng==gm.roomeng?'selected':''"> {{gm.Gamename}} </span>
              </div>
            </td>
          </tr>

        </table>
        <table class="com-table w-t3">
          <tr>
            <th colspan="2">种类 
              <template v-if="selectGame.frequency=='day'">
                <span :class="zoj==1?'active':''" @click="zoj=1" class="panqie">正盘</span>
                     <span :class="zoj==2?'active':''" @click="zoj=2" class="panqie">加时盘</span> 
              </template>
              </th>
            <th v-show="showA">A盘退水%</th>
            <th v-show="showB">B盘退水%</th>
            <th v-show="showC">C盘退水%</th>
            <th v-show="showD">D盘退水%</th>
            <th>单注最低</th>
            <th>单注最高</th>
            <th>单期最高</th>
          </tr>
          <tr v-for="(item,index) in tableData" :key="item.id">

            <td style="width:100px" :rowspan="item.rowspan?item.rowspan:1" v-if="item.rowspan" @click="showMX(index,item.rowspan,item)">
              {{item.label}}
              <!-- <img class="zhankai" v-if="item.rowspan>1" width="14px" height="14px" src="../../assets/showclose.png" alt=""> -->
            </td>
            <td v-if="item.show">{{item.label1}} <span class="blue">{{item.rowspan>1&&!item.zk?'':''}}</span> </td>
            <td v-show="showA" :rowspan="item.rowspan?item.rowspan:1" v-if="item.rowspan">
              {{item.tuishuia}}
            </td>
            <td v-show="showB" :rowspan="item.rowspan?item.rowspan:1" v-if="item.rowspan">{{item.tuishuib}}</td>
            <td v-show="showC" :rowspan="item.rowspan?item.rowspan:1" v-if="item.rowspan">{{item.tuishuic}}</td>
            <td v-show="showD" :rowspan="item.rowspan?item.rowspan:1" v-if="item.rowspan">{{item.tuishuid}}</td>
            <td v-show="item.show">{{zoj==1?item.minamount:item.jsminamount}}</td>
            <td v-show="item.show">{{zoj==1?item.maxamount:item.jsmaxamount}}</td>
            <td v-show="item.show">{{zoj==1?item.maxamountexpect:item.jsmaxamountexpect}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  lhcData,
  pk10Data,
  pcddData,
  bgcData,
  ftData,
  kl8Data,
  syData,
  klsfData,
  sscData,
  pl3Data,
  k3Data,
} from "./tuishuiData";
export default {
  name: "",
  props: [""],
  data() {
    return {
      zoj:1,
      lhcData,
      pk10Data,
      pcddData,
      bgcData,
      ftData,
      kl8Data,
      syData,
      klsfData,
      sscData,
      pl3Data,
      k3Data,
      gamelist: [],
      roomid: "",
      tableData: [],
      Gametype: "",
      roomeng: "",
      selectGame:{},
      panArr:[],
      showA:true,
      showB:true,
      showC:true,
      showD:true,
      dataSource:[],
      pages: {
        pagenum: 1,
        pagecount: 20,
      },
      totalPage: 1,
      totals: 1,
      walletnameeng:''
    };
  },

  components: {},
  created() {
   
    // this.getGameTypes();
    this.getGameList();
 
    this.getList()
  },
  mounted() {},
  watch: {
    walletnameeng(val){
        this.pages.pagenum=1
          this.getList()
     
    },
    zbdate(val){
      this.pages.pagenum=1
    this.getList()
    },
    selectGame(val){
      this.panArr=[]
      if(val.pana==1){
        this.panArr.push('A')
            }
            if(val.panb==1){
              this.panArr.push('B')
            }
            if(val.panc==1){
              this.panArr.push('C')
            }
            if(val.pand==1){
              this.panArr.push('D')
            }
     this.showA=this.panArr.includes('A')
     this.showB=this.panArr.includes('B')
     this.showC=this.panArr.includes('C')
     this.showD=this.panArr.includes('D')
 
    },
    Gametype(val) {
      switch (val) {
        case "pk10":
          this.tableData = JSON.parse(JSON.stringify(this.pk10Data));
          break;
        case "ssc":
          this.tableData = JSON.parse(JSON.stringify(this.sscData));
          break;
        case "20x8":
          this.tableData = JSON.parse(JSON.stringify(this.klsfData));
          break;
        case "11x5":
          this.tableData = JSON.parse(JSON.stringify(this.syData));
          break;
        case "80x20":
          this.tableData = JSON.parse(JSON.stringify(this.kl8Data));
          break;
        case "pcdd":
          this.tableData = JSON.parse(JSON.stringify(this.pcddData));
          break;
        case "fantan":
          this.tableData = JSON.parse(JSON.stringify(this.ftData));
          break;
        case "bingo":
          this.tableData = JSON.parse(JSON.stringify(this.bgcData));
          break;
        case "k3":
          this.tableData = JSON.parse(JSON.stringify(this.k3Data));
          break;
        case "pl3":
          this.tableData = JSON.parse(JSON.stringify(this.pl3Data));
          break;
        case "hk6":
          this.tableData = JSON.parse(JSON.stringify(this.lhcData));
          break;
        case "k3dw":
          this.tableData = [];
          break;
      }
      this.tableData.forEach((element) => {
        if (element.rowspan) {
          element.show = true;
          if (element.rowspan > 1) {
            element.zk = false;
          }
        } else {
          element.show = true;
        }
      });
    },
  },
  computed: {
    ...mapState(["userInfo", "accInfo", "currentGame",'jspanOpen','zl_zb','zbdate','currentQianbao']),
    filterQianbao() {
      let arr = [];
      if (this.userInfo.wallettype == "credit") {
        arr = this.accInfo.walletinfos.filter(
          (item) => item.wallettype == "credit"
        );
      } else if (this.userInfo.wallettype == "cash") {
        arr = this.accInfo.walletinfos.filter(
          (item) => item.wallettype == "cash"
        );
      }
      let amountarr=arr.filter(item=>item.amount>0)
      return amountarr;
    },
  },

  methods: {
    getList(){
      // uuid sid wallettype begindate enddate pagenum pagecount
      let obj={
        uuid: this.userInfo.Uuid,
      sid: this.userInfo.Sid,
      // wallettype:this.currentQianbao.wallettype,
      walletnameeng:this.walletnameeng,
      begindate:this.splitDate(this.zbdate),
      enddate:this.splitDate(this.zbdate),
      pagenum: this.pages.pagenum,
        pagecount: this.pages.pagecount,

      }
      this.$http.post('getmoneylogs',obj).then((res)=>{
        if(res.Status){
          if(res.Msg){
            let resObj = JSON.parse(res.Msg);
        
          this.dataSource=resObj.data
          console.log( this.dataSource,291)
          this.totalPage = resObj.maxpagenum;
              this.totals = resObj.maxrecordnum;
          }else{
            this.dataSource=[]
          }
        
        }else{
          this.$message.error(res.Msg);
        }
      })
    },
       // 当前页面
       handleCurrentChange(page) {
      this.pages.pagenum = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.pages.pagecount = val;
      this.pages.pagenum = 1;
      this.getList();
    },
    setzlzb(num){
      this.$store.commit('SETZLORZB',num)
      if(num==2){
        this.pages.pagenum=1
    this.getList()
      }
    },
    getCurrentPan(str) {
      let pan = this.panArr.find((ele) => ele == str);

      return pan ? true : false;
    },
    changeGame(item) {
      this.selectGame=item
      if(this.selectGame.frequency!='day'){
        this.zoj=1
      }
      this.Gametype = item.Gametype;
      this.roomeng = item.roomeng;
      this.getClassset();
    },
    // 获取所有游戏列表
    // 查询游戏列表
    getGameList() {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        official: 0,
      };

      this.$http.post("getgamelist", obj).then((res) => {
        if (res.Status) {
          let obj = JSON.parse(res.Msg);

          let gameArr = obj.GameInfoS;
          this.gamelist = gameArr;
          if (this.currentGame.roomeng) {
            this.selectGame=this.currentGame
            this.Gametype = this.currentGame.Gametype;
            this.roomeng = this.currentGame.roomeng;
          } else {
            this.selectGame=this.gamelist[0]
            this.Gametype = this.gamelist[0].Gametype;
            this.roomeng = this.gamelist[0].roomeng;
          }
          if(this.selectGame.frequency!='day'){
        this.zoj=1
      }
          this.getClassset();
        
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    // 显示与隐藏
    showMX(index, rowspan, item) {
      if (rowspan > 1) {
        item.zk = !item.zk;
        for (let i = index + 1; i < index + rowspan; i++) {
          this.tableData[i].show = !this.tableData[i].show;
        }
        this.$forceUpdate();
      }
    },
    //  获取用户赔率信息
    getClassset() {
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        // destaccount: this.userInfo.account,
        roomeng: this.roomeng,
      };
      this.$http.post("getuserportclassset", obj).then((res) => {
        if (res.Status) {
          let portList = JSON.parse(res.Msg);
          // console.log(portList, "请求回来的列表");
          if (portList) {
            this.tableData.forEach((item) => {
              let ele = portList.find((ele) => ele.portid == item.id);
              // console.log(ele);
              if (ele) {
                item.maxamount = ele.maxamount;
                item.maxamountexpect = ele.maxamountexpect;
                item.maxodds = ele.maxodds;
                item.minamount = ele.minamount;
                item.jsminamount=ele.jsminamount;
                item.jsmaxamount=ele.jsmaxamount;
                item.jsmaxamountexpect=ele.jsmaxamountexpect;
                item.oddsa = ele.oddsa;
                item.oddsb = ele.oddsb;
                item.oddsc = ele.oddsc;
                item.oddsd = ele.oddsd;
                item.portid = ele.portid;
                item.roomid = ele.roomid;

                item.tuishuia = ele.tuishuia;
                item.tuishuib = ele.tuishuib;
                item.tuishuic = ele.tuishuic;
                item.tuishuid = ele.tuishuid;
                item.uuid = ele.uuid;
              }
            });

            this.$forceUpdate();
          }

          // console.log(this.tableData, "zhenzhen列表");
        } else {
          this.$message.warning(res.Msg);
        }
      });
    },
    // 获取游戏分类
    // getGameTypes() {
    //   let obj = {
    //     uuid: this.userInfo.Uuid,
    //     sid: this.userInfo.Sid,
    //     key: "gametype",
    //     platform: "lottery",
    //   };
    //   this.$http
    //     .post("getgametype", obj)
    //     .then((res) => {
    //       if (res.Status) {
    //         let resArr = JSON.parse(res.msg);
    //         console.log(resArr, 213213123213);
    //       } else {
    //         this.$message.warning(res.Msg);
    //       }
    //     })
    //     .catch((err) => {});
    // },
  },
};
</script>
<style  scoped>
span.active{
  color: red;
}
.skin-brown .panqie.active{
  color: #fff;
  background: #dfb55e;
  border: 1px solid #dfb55e;
}
.skin-red .panqie.active{
  color: #fff;
  background: #c2455c;
  border: 1px solid #c2455c;
}
.skin-blue .panqie.active{
  color: #fff;
  background: #2161b3;
  border: 1px solid #2161b3;
}
.panqie{
  margin-left: 10px;
  display: inline-block;
  width: 40px;
  height: 20px;
  border: 1px solid #999;
  text-align: center;
  background: #f7f7f7;
  font-size: 10px;
  line-height: 20px;
  border-radius: 4px;
}
.flextd {
  width: 100%;
  height: 100%;
  /* line-height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.red {
  color: red;
}
.zhankai {
  position: absolute;
  /* top: 3px; */
  right: 4px;
}
.com-table.w-t3 td,
.com-table.w-t3 th {
  width: auto !important;
  text-align: center !important;
  vertical-align: middle;
  padding-right: 0 !important;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
td {
  position: relative;
}
.water-os .com-table.w-t2 .tab span.selected,
.water-os .com-table.w-t2 .tab span:hover {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #fff;
}
.skin-brown .inside-os .water-os .com-table.w-t2 .tab span.selected,
.skin-brown .inside-os .water-os .com-table.w-t2 .tab span:hover {
  background: #dfb55e;
  text-shadow: none;
}
.skin-red .inside-os .water-os .com-table.w-t2 .tab span.selected,
.skin-red .inside-os .water-os .com-table.w-t2 .tab span:hover {
  background: #c2455c;
  text-shadow: none;
}
.skin-blue .inside-os .water-os .com-table.w-t2 .tab span.selected,
.skin-blue .inside-os .water-os .com-table.w-t2 .tab span:hover {
  background: #2161b3;
  text-shadow: none;
}
.water-os .com-table.w-t2 .tab span {
  margin: 5px 0 0 5px;
  width: calc((100% - 50px) / 9);
  height: 30px;
  line-height: 30px;
  background: #ddd;
  text-align: center;
  text-shadow: 1px 1px 0 #eee;
  cursor: pointer;
}
.water-os .com-table.w-t2 .tab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
th.kkc {
  text-align: center !important;
  vertical-align: middle !important;
}
.skin-brown .inside-os .com-table th.kkc {
  background: linear-gradient(180deg, #fff, #fff 15px, #fdeee1 51%, #fdeee1);
}
.skin-red .inside-os .com-table th.kkc {
  background: linear-gradient(180deg, #fff, #fff 15px, #ffefee 51%, #ffefee);
}
.skin-blue .inside-os .com-table th.kkc {
  background: linear-gradient(180deg, #fff, #fff 15px, #fef4ea 51%, #fef4ea);
}
.water-os .com-table.w-t2 th {
  width: 60px;
}
.water-os .com-table.w-t2 {
  margin-top: 5px;
}
.water-os .btn span {
  width: 80px;
}
.inside-os .button {
  padding: 0 8px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.water-os .btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  padding: 15px 0 0 260px;
  width: 242px;
}
.inside-os .com-table td,
.inside-os .com-table th {
  padding: 4px 0;
  text-align: center;
  font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif;
}
.water-os .com-table th {
  text-align: left;
  padding-left: 10px;
}
.water-os .com-table input {
  width: 230px;
  padding: 0 5px;
}

.inside-os input[type="password"],
.inside-os input[type="text"] {
  height: 26px;
  line-height: 26px;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 2px 0 #e8e8e8;
  box-shadow: inset 0 1px 2px 0 #e8e8e8;
  -webkit-appearance: none;
}
.water-os .com-table tr td:last-child {
  text-align: left;
  padding-left: 15px;
}
.water-os .com-table tr td:first-child {
  width: 230px;
  text-align: right;
  padding-right: 15px;
}
.inside-os .com-table {
  width: 100%;
}
.water-os {
  padding-top: 10px;
}
/* .inside-os {
  width: 1018px;
  padding: 0 0 10px 10px;
  color: #4f4d4d;
} */
</style>