// 六合彩赔率
export const lhcData = [
  { label: "特码A", label1: "", id: 16, rowspan: 1 },
  { label: "特码B", label1: "", id: 18, rowspan: 1 },
  { label: "特码两面", label1: "特码单双", id: 688, rowspan: 6 }, //没有id
  { label: "特码两面", label1: "特码大小", id: 689 },
  { label: "特码两面", label1: "特合数单双", id: 690 },
  { label: "特码两面", label1: "特合数大小", id: 691 },
  { label: "特码两面", label1: "特家禽野兽", id: 692 },
  // { label: "特码两面", label1: "特尾单双", id: 699 },
  { label: "特码两面", label1: "特大小尾", id: 693 },
  { label: "总和两面", label1: "", id: 695, rowspan: 1 },
  // { label: "总和两面", label1: "总和小", id: null },
  { label: "特码半特", label1: "", id: 694, rowspan: 1 },
  { label: "特码色波", label1: "红波", id: 21, rowspan: 3 },
  { label: "特码色波", label1: "绿波", id: 22 },
  { label: "特码色波", label1: "蓝波", id: 624 },
  { label: "特码半波", label1: "红大蓝小绿小", id: 26, rowspan: 7 },
  { label: "特码半波", label1: "红小", id: 27 },
  { label: "特码半波", label1: "绿大", id: 28 },
  { label: "特码半波", label1: "蓝大", id: 29 },
  { label: "特码半波", label1: "红单蓝单蓝双绿单", id: 30 },
  { label: "特码半波", label1: "红双", id: 31 },
  { label: "特码半波", label1: "绿双", id: 32 },
  { label: "特码半半波", label1: "红大单蓝小单绿小双", id: 33, rowspan: 3 },
  { label: "特码半半波", label1: "其他半半波", id: 34 },
  { label: "特码半半波", label1: "红小单红小双蓝大单", id: 35 },
  { label: "特肖", label1: "普肖", id: 38, rowspan: 2 },
  { label: "特肖", label1: "年肖", id: 39 },
  { label: "特码合肖", label1: "2肖", id: 46, rowspan: 10 },
  { label: "特码合肖", label1: "3肖", id: 47 },
  { label: "特码合肖", label1: "4肖", id: 48 },
  { label: "特码合肖", label1: "5肖", id: 49 },
  { label: "特码合肖", label1: "6肖", id: 50 },
  { label: "特码合肖", label1: "7肖", id: 51 },
  { label: "特码合肖", label1: "8肖", id: 52 },
  { label: "特码合肖", label1: "9肖", id: 53 },
  { label: "特码合肖", label1: "10肖", id: 54 },
  { label: "特码合肖", label1: "11肖", id: 55 },
  { label: "特码头尾", label1: "0头", id: 56, rowspan: 4 },
  { label: "特码头尾", label1: "其他头", id: 57 },
  { label: "特码头尾", label1: "0尾", id: 58 },
  { label: "特码头尾", label1: "其他尾", id: 59 },
  { label: "正码", label1: "", id: 19, rowspan: 1 },
  { label: "正码特", label1: "", id: 10, rowspan: 1 },
  // { label: "正码特", label1: "正二特", id: 11 },
  // { label: "正码特", label1: "正三特", id: 12 },
  // { label: "正码特", label1: "正四特", id: 13 },
  // { label: "正码特", label1: "正五特", id: 14 },
  // { label: "正码特", label1: "正六特", id: 15 },
  { label: "正码1-6两面", label1: "单双", id: 25, rowspan: 5 },
  { label: "正码1-6两面", label1: "大小", id: 684 },
  { label: "正码1-6两面", label1: "合数单双", id: 685 },
  { label: "正码1-6两面", label1: "合数大小", id: 686 },
  // { label: "正码1-6两面", label1: "尾数单双", id: 700 },
  { label: "正码1-6两面", label1: "尾数大小", id: 687 },
  { label: "正码色波", label1: "红波", id: 23, rowspan: 3 },
  { label: "正码色波", label1: "绿波", id: 24 },
  { label: "正码色波", label1: "蓝波", id: 625 },
  { label: "自选不中", label1: "5不中", id: 238, rowspan: 8 },
  { label: "自选不中", label1: "6不中", id: 239 },
  { label: "自选不中", label1: "7不中", id: 240 },
  { label: "自选不中", label1: "8不中", id: 241 },
  { label: "自选不中", label1: "9不中", id: 242 },
  { label: "自选不中", label1: "10不中", id: 243 },
  { label: "自选不中", label1: "11不中", id: 244 },
  { label: "自选不中", label1: "12不中", id: 611 },
  { label: "连肖", label1: "二连肖-普肖", id: 212, rowspan: 8 },
  { label: "连肖", label1: "二连肖-年肖", id: 213 },
  { label: "连肖", label1: "三连肖-普肖", id: 214 },
  { label: "连肖", label1: "三连肖-年肖", id: 215 },
  { label: "连肖", label1: "四连肖-普肖", id: 216 },
  { label: "连肖", label1: "四连肖-年肖", id: 217 },
  { label: "连肖", label1: "五连肖-普肖", id: 218 },
  { label: "连肖", label1: "五连肖-年肖", id: 219 },
  { label: "连尾", label1: "二连尾-其他尾", id: 226, rowspan: 8 },
  { label: "连尾", label1: "二连尾-0尾", id: 227 },
  { label: "连尾", label1: "三连尾-其他尾", id: 228 },
  { label: "连尾", label1: "三连尾-0尾", id: 229 },
  { label: "连尾", label1: "四连尾-其他尾", id: 230 },
  { label: "连尾", label1: "四连尾-0尾", id: 231 },
  { label: "连尾", label1: "五连尾-其他尾", id: 622 },
  { label: "连尾", label1: "五连尾-0尾", id: 623 },
  { label: "连码", label1: "三中二-中二", id: 245, rowspan: 8 },
  { label: "连码", label1: "三中二-中三", id: 257 },
  { label: "连码", label1: "三全中", id: 258 },
  { label: "连码", label1: "二全中", id: 259 },
  { label: "连码", label1: "二中特-中特", id: 260 },
  { label: "连码", label1: "二中特-中二", id: 261 },
  { label: "连码", label1: "特串", id: 262 },
  { label: "连码", label1: "四全中", id: 640 },
  { label: "一肖尾数", label1: "普肖", id: 36, rowspan: 4 },
  { label: "一肖尾数", label1: "年肖", id: 37 },
  { label: "一肖尾数", label1: "0尾", id: 683 },
  { label: "一肖尾数", label1: "其他尾", id: 626 },
  { label: "正肖", label1: "普肖", id: 618, rowspan: 2 },
  { label: "正肖", label1: "年肖", id: 621 },
  { label: "七色波", label1: "红波", id: 614, rowspan: 4 },
  { label: "七色波", label1: "绿波", id: 615 },
  { label: "七色波", label1: "蓝波", id: 616 },
  { label: "七色波", label1: "和局", id: 617 },
  { label: "总肖", label1: "2肖", id: 40, rowspan: 8 },
  { label: "总肖", label1: "3肖", id: 41 },
  { label: "总肖", label1: "4肖", id: 42 },
  { label: "总肖", label1: "5肖", id: 43 },
  { label: "总肖", label1: "6肖", id: 44 },
  { label: "总肖", label1: "7肖", id: 45 },
  { label: "总肖", label1: "总肖单", id: 263 },
  { label: "总肖", label1: "总肖双", id: 264 },
];
// 快3 赔率
export const k3Data = [
  { label: "三军", label1: "中一", id: 140, rowspan: 3 },
  { label: "三军", label1: "中二", id: 141 },
  { label: "三军", label1: "中三", id: 142 },
  { label: "三同号", label1: "", id: 144, rowspan: 1 },
  { label: "二不同号", label1: "", id: 155, rowspan: 1 },
  { label: "二同号复选", label1: "", id: 156, rowspan: 1 },
  { label: "二同号单选", label1: "", id: 146, rowspan: 1 },
  { label: "三不同号单选", label1: "", id: 147, rowspan: 1 },
  { label: "点数", label1: "3,18", id: 606, rowspan: 7 },
  { label: "点数", label1: "4,17", id: 148 },
  { label: "点数", label1: "5,16", id: 149 },
  { label: "点数", label1: "6,15", id: 150 },
  { label: "点数", label1: "7,14", id: 151 },
  { label: "点数", label1: "8,13", id: 152 },
  { label: "点数", label1: "9,10,11,12", id: 153 },
  // { label: "全骰", label1: "", id: 145, rowspan: 1 },
  { label: "豹顺杂", label1: "豹子", id: 145, rowspan: 5 },
  { label: "豹顺杂", label1: "对子", id: 609 },
  { label: "豹顺杂", label1: "全顺", id: 416 },
  { label: "豹顺杂", label1: "半顺", id: 417 },
  { label: "豹顺杂", label1: "杂", id: 418 },
  { label: "两面", label1: "两面", id: 143, rowspan: 3 },
  { label: "两面", label1: "红码", id: 419 },
  { label: "两面", label1: "黑码", id: 420 },
  { label: "两面过关", label1: "大单小双", id: 413, rowspan: 4 },
  { label: "两面过关", label1: "大双小单", id: 608 },
  { label: "两面过关", label1: "红两面", id: 414 },
  { label: "两面过关", label1: "黑两面", id: 415 },
  { label: "跨度", label1: "15", id: 424, rowspan: 2 },
  { label: "跨度", label1: "234", id: 425 },
];
// 排列3
export const pl3Data = [
  { label: "两面", label1: "两面", id: 485, rowspan: 3 },
  { label: "两面", label1: "总数质", id: 675 },
  { label: "两面", label1: "总数合", id: 676 },
  { label: "一字", label1: "", id: 484, rowspan: 1 },
  { label: "二字", label1: "重号", id: 488, rowspan: 2 },
  { label: "二字", label1: "不重号", id: 489 },
  { label: "三字", label1: "不重号", id: 490, rowspan: 3 },
  { label: "三字", label1: "重号", id: 491 },
  { label: "三字", label1: "豹子", id: 492 },
  { label: "一字定位", label1: "", id: 493, rowspan: 1 },
  // { label: "一字定位", label1: "拾", id: 494 },
  // { label: "一字定位", label1: "个", id: 495 },
  { label: "二字定位", label1: "", id: 496, rowspan: 1 },
  { label: "三字定位", label1: "", id: 497, rowspan: 1 },
  { label: "组选三", label1: "两球", id: 737, rowspan: 9 },
  { label: "组选三", label1: "三球", id: 738 },
  { label: "组选三", label1: "四球", id: 739 },
  { label: "组选三", label1: "五球", id: 527 },
  { label: "组选三", label1: "六球", id: 528 },
  { label: "组选三", label1: "七球", id: 529 },
  { label: "组选三", label1: "八球", id: 530 },
  { label: "组选三", label1: "九球", id: 531 },
  { label: "组选三", label1: "十球", id: 532 },
  { label: "组选六", label1: "三球", id: 769 , rowspan: 8},
  { label: "组选六", label1: "四球", id: 533 },
  { label: "组选六", label1: "五球", id: 534 },
  { label: "组选六", label1: "六球", id: 535 },
  { label: "组选六", label1: "七球", id: 536 },
  { label: "组选六", label1: "八球", id: 537 },
  { label: "组选六", label1: "九球", id: 740 },
  { label: "组选六", label1: "十球", id: 741 },
  { label: "跨度0", label1: "", id: 538, rowspan: 1 },
  { label: "跨度1", label1: "", id: 539, rowspan: 1 },
  { label: "跨度2", label1: "", id: 540, rowspan: 1 },
  { label: "跨度3", label1: "", id: 541, rowspan: 1 },
  { label: "跨度4", label1: "", id: 542, rowspan: 1 },
  { label: "跨度5", label1: "", id: 543, rowspan: 1 },
  { label: "跨度6", label1: "", id: 544, rowspan: 1 },
  { label: "跨度7", label1: "", id: 545, rowspan: 1 },
  { label: "跨度8", label1: "", id: 546, rowspan: 1 },
  { label: "跨度9", label1: "", id: 547, rowspan: 1 },
  { label: "和值0", label1: "", id: 510, rowspan: 1 },
  { label: "和值1", label1: "", id: 662, rowspan: 1 },
  { label: "和值2", label1: "", id: 663, rowspan: 1 },
  { label: "和值3", label1: "", id: 664, rowspan: 1 },
  { label: "和值4", label1: "", id: 665, rowspan: 1 },
  { label: "和值5", label1: "", id: 666, rowspan: 1 },
  { label: "和值6", label1: "", id: 667, rowspan: 1 },
  { label: "和值7", label1: "", id: 511, rowspan: 1 },
  { label: "和值8", label1: "", id: 512, rowspan: 1 },
  { label: "和值9", label1: "", id: 513, rowspan: 1 },
  { label: "和值10", label1: "", id: 514, rowspan: 1 },
  { label: "和值11", label1: "", id: 515, rowspan: 1 },
  { label: "和值12", label1: "", id: 516, rowspan: 1 },
  { label: "和值13", label1: "", id: 517, rowspan: 1 },
  { label: "和值14", label1: "", id: 518, rowspan: 1 },
  { label: "和值15", label1: "", id: 519, rowspan: 1 },
  { label: "和值16", label1: "", id: 520, rowspan: 1 },
  { label: "和值17", label1: "", id: 521, rowspan: 1 },
  { label: "和值18", label1: "", id: 522, rowspan: 1 },
  { label: "和值19", label1: "", id: 523, rowspan: 1 },
  { label: "和值20", label1: "", id: 524, rowspan: 1 },
  { label: "和值21", label1: "", id: 525, rowspan: 1 },
  { label: "和值22", label1: "", id: 668, rowspan: 1 },
  { label: "和值23", label1: "", id: 669, rowspan: 1 },
  { label: "和值24", label1: "", id: 670, rowspan: 1 },
  { label: "和值25", label1: "", id: 671, rowspan: 1 },
  { label: "和值26", label1: "", id: 672, rowspan: 1 },
  { label: "和值27", label1: "", id: 673, rowspan: 1 },
];
// 时时彩 数据
export const sscData = [
  { label: "1-5单号", label1: "", id: 1, rowspan: 1 },
  { label: "两面", label1: "", id: 2, rowspan: 1 },
  { label: "龙虎和", label1: "龙虎", id: 3, rowspan: 3 },
  { label: "龙虎和", label1: "龙虎B", id: 83 },
  { label: "龙虎和", label1: "和", id: 4 },
  // { label: "龙虎和", label1: "和", id: 84 },
  { label: "前中后", label1: "豹子", id: 5, rowspan: 5 },
  { label: "前中后", label1: "顺子", id: 6 },
  { label: "前中后", label1: "对子", id: 7 },
  { label: "前中后", label1: "半顺", id: 8 },
  { label: "前中后", label1: "杂六", id: 9 },
  // { label: "斗牛", label1: "牛牛", id: 576, rowspan: 15 },
  // { label: "斗牛", label1: "牛一", id: 585 },
  // { label: "斗牛", label1: "牛二", id: 584 },
  // { label: "斗牛", label1: "牛三", id: 583 },
  // { label: "斗牛", label1: "牛四", id: 582 },
  // { label: "斗牛", label1: "牛五", id: 581 },
  // { label: "斗牛", label1: "牛六", id: 580 },
  // { label: "斗牛", label1: "牛七", id: 579 },
  // { label: "斗牛", label1: "牛八", id: 578 },
  // { label: "斗牛", label1: "牛九", id: 577 },
  // { label: "斗牛", label1: "无牛", id: 586 },
  // { label: "斗牛", label1: "牛大", id: 587 },
  // { label: "斗牛", label1: "牛小", id: 588 },
  // { label: "斗牛", label1: "牛单", id: 589 },
  // { label: "斗牛", label1: "牛双", id: 590 },
];
// 快乐十分
export const klsfData = [
  { label: "1-8单号", label1: "", id: 120, rowspan: 1 },
  { label: "两面", label1: "", id: 121, rowspan: 1 },
  { label: "方位四季", label1: "东南西北", id: 122, rowspan: 2 },
  { label: "方位四季", label1: "春夏秋冬", id: 620, db: true },
  { label: "中发白", label1: "中发", id: 123, rowspan: 2 },
  { label: "中发白", label1: "白", id: 124 },
  { label: "正码", label1: "", id: 125, rowspan: 1 },
  { label: "连码", label1: "任选二", id: 126, rowspan: 6 },
  { label: "连码", label1: "选二连组", id: 127 },
  { label: "连码", label1: "任选三", id: 128 },
  { label: "连码", label1: "选三前组", id: 129 },
  { label: "连码", label1: "任选四", id: 130 },
  { label: "连码", label1: "任选五", id: 131 },
];
// 11选5
export const syData = [
  { label: "1-5单号", label1: "", id: 171, rowspan: 1 },
  { label: "两面", label1: "", id: 172, rowspan: 1 },
  { label: "一中一", label1: "", id: 177, rowspan: 1 },
  { label: "连码", label1: "二中二", id: 178, rowspan: 7 },
  { label: "连码", label1: "三中三", id: 179 },
  { label: "连码", label1: "四中四", id: 180 },
  { label: "连码", label1: "五中五", id: 181 },
  { label: "连码", label1: "六中五", id: 182 },
  { label: "连码", label1: "七中五", id: 183 },
  { label: "连码", label1: "八中五", id: 184 },
  { label: "组选", label1: "前二", id: 185, rowspan: 2 },
  { label: "组选", label1: "前三", id: 186 },
  { label: "直选", label1: "前二", id: 187, rowspan: 2 },
  { label: "直选", label1: "前三", id: 188 },
];
// 快乐8
export const kl8Data = [
  // { label: "正码", label1: "", id: 246, rowspan: 1 },
  { label: "两面", label1: "", id: 247, rowspan: 1 },
  // { label: "总和和局", label1: "", id: 248, rowspan: 1 },
  // { label: "总和过关", label1: "", id: 249, rowspan: 1 },
  // { label: "前后和", label1: "前多后多", id: 250, rowspan: 2 },
  // { label: "前后和", label1: "和", id: 251 },
  // { label: "单双和", label1: "单多双多", id: 252, rowspan: 2 },
  // { label: "单双和", label1: "和", id: 253 },
  // { label: "五行", label1: "金土", id: 255, rowspan: 3 },
  // { label: "五行", label1: "木火", id: 254 },
  // { label: "五行", label1: "水", id: 256 },
  // { label: "二连赢", label1: "", id: 678, rowspan: 1 },
  // { label: "三连赢", label1: "", id: 679, rowspan: 1 },
  // { label: "四连赢", label1: "", id: 680, rowspan: 1 },
  // { label: "五连赢", label1: "", id: 681, rowspan: 1 },
  // { label: "六连赢", label1: "", id: 682, rowspan: 1 },
  { label: "福禄寿喜", label1: "", id: 736, rowspan: 1 },
  
];
// 翻摊
// export const ftData = [
//   { label: "两面", label1: "", id: 599, rowspan: 1 },
//   { label: "番", label1: "", id: 600, rowspan: 1 },
//   { label: "念", label1: "", id: 601, rowspan: 1 },
//   { label: "角", label1: "", id: 602, rowspan: 1 },
//   { label: "通", label1: "", id: 603, rowspan: 1 },
//   { label: "正", label1: "", id: 604, rowspan: 1 },
//   { label: "三门", label1: "", id: 605, rowspan: 1 },
// ];
export const ftData = [
  { label: "两面", label1: "", id: 599, rowspan: 1 },
  { label: "番", label1: "", id: 600, rowspan: 1 },
  { label: "念", label1: "", id: 601, rowspan: 1 },
  { label: "角", label1: "", id: 602, rowspan: 1 },
  { label: "单通", label1: "", id: 725, rowspan: 1 },
  { label: "双通", label1: "", id: 603, rowspan: 1},
  { label: "正", label1: "", id: 604, rowspan: 1 },
  { label: "三门", label1: "", id: 605, rowspan: 1 },
];
// 宾果彩
export const bgcData = [
  { label: "两面", label1: "", id: 677, rowspan: 1 },
  { label: "连赢", label1: "二连赢", id: 678, rowspan: 5 },
  { label: "连赢", label1: "三连赢", id: 679 },
  { label: "连赢", label1: "四连赢", id: 680 },
  { label: "连赢", label1: "五连赢", id: 681 },
  { label: "连赢", label1: "六连赢", id: 682 },
];
// PC蛋蛋
export const pcddData = [
  { label: "和值两面", label1: "大小单双", id: 266, rowspan: 1 },
  { label: "极值大小", label1: "", id: 267, rowspan: 1 },
  { label: "和值过关", label1: "大单小双", id: 268, rowspan: 2 },
  { label: "和值过关", label1: "小单大双", id: 775, },
  { label: "和值", label1: "0,27", id: 269, rowspan: 14 },
  { label: "和值", label1: "1,26", id: 270 },
  { label: "和值", label1: "2,25", id: 271 },
  { label: "和值", label1: "3,24", id: 272 },
  { label: "和值", label1: "4,23", id: 273 },
  { label: "和值", label1: "5,22", id: 274 },
  { label: "和值", label1: "6,21", id: 275 },
  { label: "和值", label1: "7,20", id: 701 },
  { label: "和值", label1: "8,19", id: 702 },
  { label: "和值", label1: "9,18", id: 703 },
  { label: "和值", label1: "10,17", id: 704 },
  { label: "和值", label1: "11,16", id: 705 },
  { label: "和值", label1: "12,15", id: 706 },
  { label: "和值", label1: "13,14", id: 707 },
  { label: "色波", label1: "蓝", id: 276, rowspan: 3 },
  { label: "色波", label1: "红", id: 774, },
  { label: "色波", label1: "绿", id: 778, },
  { label: "豹顺对", label1: "豹子", id: 277, rowspan: 3 },
  { label: "豹顺对", label1: "顺子", id: 776,  },
  { label: "豹顺对", label1: "对子", id: 777,  },
  // { label: "包三", label1: "", id: 278, rowspan: 1 },
  { label: "定位", label1: "两面", id: 779, rowspan: 1 },
  { label: "定位", label1: "胆", id: 770, rowspan: 1 },
  { label: "百家乐", label1: "庄闲", id: 771, rowspan: 3 },
  { label: "百家乐", label1: "和", id: 772},
  { label: "百家乐", label1: "庄闲对", id: 773},
];
// pk10
export const pk10Data = [
  { label: "1-10单号", label1: "", id: 201, rowspan: 1 },
  { label: "两面", label1: "", id: 202, rowspan: 1 },
  { label: "冠亚两面", label1: "冠亚大", id: 208, rowspan: 4 },
  { label: "冠亚和大小", label1: "冠亚小", id: 209 },
  { label: "冠亚和单双", label1: "冠亚单", id: 210 },
  { label: "冠亚和单双", label1: "冠亚双", id: 211 },
  { label: "冠亚和", label1: "3,4,18,19", id: 203, rowspan: 5 },
  { label: "冠亚和", label1: "5,6,15,17", id: 204 },
  { label: "冠亚和", label1: "7,8,14,15", id: 205 },
  { label: "冠亚和", label1: "9,10,12,13", id: 206 },
  { label: "冠亚和", label1: "11", id: 207 },
];
// 五星彩
export const wxcData = [
  { label: "一字定", label1: "", id: 709, rowspan: 1 },
  { label: "二字定", label1: "二字定", id: 710, rowspan: 2 },
  { label: "二字定", label1: "五位二定", id: 713 },
  { label: "三字定", label1: "", id: 711, rowspan: 1 },
  { label: "四字定", label1: "", id: 712, rowspan: 1 },
  { label: "二字现", label1: "不重号", id: 714, rowspan: 2 },
  { label: "二字现", label1: "双重号", id: 715, },
  { label: "三字现", label1: "不重号", id: 716, rowspan: 3 },
  { label: "三字现", label1: "双重号", id: 717, },
  { label: "三字现", label1: "三重号", id: 718, },
  { label: "四字现", label1: "不重号", id: 719, rowspan: 4 },
  { label: "四字现", label1: "双重号", id: 720, },
  { label: "四字现", label1: "三重号", id: 721, },
  { label: "四字现", label1: "四重号", id: 722, },
];
